import React from "react";
import { Helmet } from "react-helmet";
import {
  Hero,
  Welcome,
  Services,
  About,
  Projects,
  Record,
} from "./index";

function Home() {
  return (
    <>
      <Helmet>
        <title>Infinet - Home</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name" />
        <link rel="canonical" href="https://www.yourwebsite.com/" />
      </Helmet>
      <Hero />
      <Welcome />
      <Services />
      <About />
      <Projects />
      <Record />
    </>
  );
}

export default Home;
