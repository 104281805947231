import React from "react";
import { Banner } from "../../components";
import { Team, FormContainer, Map } from "./index";
import { Helmet } from "react-helmet";
import Image from "../../assets/img/contact-banner.jpeg";

function ContactUs() {
  return (
    <>
      <Helmet>
        <title>Infinet - Contact</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta property="og:image" content={Image} />
        <link rel="canonical" href={`https://infinet.agency/contact/`} />
      </Helmet>
      <Banner image={Image} />
      <FormContainer />
      <Team />
      <Map />
    </>
  );
}

export default ContactUs;
