import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import WebsiteIcon from "./../../../assets/svg/wesbite.svg";
import WebsiteHostingIcon from "./../../../assets/svg/website-hosting.svg";
import WebsiteDesignIcon from "./../../../assets/svg/website-design.svg";
import MarketingIcon from "./../../../assets/svg/marketing.svg";

interface ItemProps {
  icon: string;
  title: string;
  text: string;
}

const Item: React.FC<ItemProps> = (props) => {
  return (
    <div className="flex flex-col gap-4 max-md:items-center max-md:w-1/2">
      <div className="">
        <img className="w-[32px] h-auto" src={props.icon} loading="lazy" alt="" />
      </div>

      <div className="flex flex-col gap-2">
        <h3 className="max-md:text-[16px] text-[20px] text-white font-syne font-semibold leading-[20px] max-md:text-center">
          {props.title}
        </h3>
        <p className="max-md:text-[12px] text-[16px] tracking-wider font-syne text-white leading-[16px] max-md:text-center">
          {props.text}
        </p>
      </div>
    </div>
  );
};

function Services() {
  const { t } = useTranslation();
  return (
    <Section position="default">
      <div className="">
        <div className="w-full flex justify-between max-md:flex-wrap max-md:gap-y-6">
          <Item
            icon={WebsiteIcon}
            title={t('home.services.website.title')}
            text={t('home.services.website.subtitle')}
          />
          <Item
            icon={WebsiteHostingIcon}
            title={t('home.services.hosting.title')}
            text={t('home.services.hosting.subtitle')}
          />
          <Item
            icon={WebsiteDesignIcon}
            title={t('home.services.it.title')}
            text={t('home.services.it.subtitle')}
          />
          <Item
            icon={MarketingIcon}
            title={t('home.services.marketing.title')}
            text={t('home.services.marketing.subtitle')}
          />
        </div>
      </div>
    </Section>
  );
}

export default Services;
