import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import "../../../styles/global.css";

function PackageOverview() {
  const { t } = useTranslation();

  return (
    <Section position="top">
        <div className="w-full flex flex-col gap-5 justify-center items-center">
          <h1 className="default-title text-white text-center">{t("price.overview.title")}</h1>
          <p className="text-center white-paragraph w-8/12">{t("price.overview.paragraph")}</p>
        </div>
    </Section>
  );
}

export default PackageOverview;
