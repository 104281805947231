import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Section } from "../../../components";
import { MdArrowOutward } from "react-icons/md";
import { useTranslation } from "react-i18next";

interface Project {
  id: string;
  title: string;
  description: string;
  image: {
    cover: string;
    banner: string;
    about: string;
  };
  about: {
    company: string;
    title: string;
    paragraphOne: string;
    paragraphTwo: string;
    website?: string;
  };
  steps: {
    challange: string;
    solution: string;
    results: string;
  };
  stats: {
    viewers: string;
    growth: string;
    conversion: string;
  };
  testimonial: {
    title: string;
    paragraph: string;
    image: string;
    name: string;
  };
  addedDate: string;
  categories: string[];
}

interface ItemProps {
  url: string;
  title: string;
  border?: "top" | "default";
  categories?: string[];
}

const Item: React.FC<ItemProps> = ({ url, title, border = "default", categories = [] }) => {
  const { t } = useTranslation();

  const getBorder = (border: string) => {
    switch (border) {
      case "top":
        return "border-t";
      default:
        return "border-y";
    }
  };

  return (
    <>
      <Link
      to={`/projects/${url}`}
      className={`max-md2:hidden flex items-center justify-between py-4 max-md:px-5 px-[2.5%] border-[#9A9A9A] ${getBorder(border)} group hover:cursor-pointer hover:py-6 transition-all duration-300 ease-in-out`}
    >
        <div>
          <h1 className="font-syne font-medium max-md:text-sm text-2xl tracking-wide text-white group-hover:text-moss-green">
            {title}
          </h1>
        </div>

        <div className="max-md:w-auto flex gap-10 justify-between">
          <div className="flex gap-4 items-center">
            {categories.map((category, index) => (
              <div key={index} id={category}>
                <span className="max-md:text-[8px] text-[12px] font-syne leading-[12px] text-white border-2 max-md:border border-moss-green px-4 py-1 rounded-full transition-all duration-300 group-hover:bg-moss-green group-hover:text-black">
                  {t(category)}
                </span>
              </div>
            ))}
          </div>

          <div className="relative overflow-hidden flex justify-center items-center w-10 h-10">
            <MdArrowOutward
              className="absolute group-hover:translate-x-8 group-hover:-translate-y-8 transition-all ease-in-out duration-500"
              color="#fff"
              size={30}
            />
            <MdArrowOutward
              className="absolute -translate-x-8 translate-y-8 transition-all ease-in-out duration-500 group-hover:translate-x-0 group-hover:translate-y-0"
              color="#fff"
              size={30}
            />
          </div>
        </div>
      </Link>

      <Link
      to={`/projects/${url}`}
      className={`md2:hidden flex flex-col py-4 max-md:px-5 px-20 border-[#9A9A9A] ${getBorder(border)} group hover:cursor-pointer hover:py-6 transition-all duration-300 ease-in-out`}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="flex">
            <h1
              className="font-syne font-medium max-md2:text-2xl text-lg leading-none pb-1.5 tracking-wide text-white group-hover:text-moss-green"
            >{title}</h1>
          </div>
          <div className="flex gap-2">
              {categories.map((category, index) => (
                <div key={index} id={category}>
                  <span className="max-md:text-[8px] max-md2:text-[8px] text-[12px] font-syne leading-none text-white border-2 max-md:border border-moss-green max-md:px-2.5 px-4 max-md:py-1 py-1 rounded-full transition-all duration-300 group-hover:bg-moss-green group-hover:text-black">
                    {t(category)}
                  </span>
                </div>
              ))}
          </div>
        </div>
        
        <div className="flex">
          <div className="relative overflow-hidden flex justify-center items-center w-10 h-full">
            <MdArrowOutward
              className="absolute group-hover:translate-x-8 group-hover:-translate-y-8 transition-all ease-in-out duration-500"
              color="#fff"
              size={30}
            />
            <MdArrowOutward
              className="absolute -translate-x-8 translate-y-8 transition-all ease-in-out duration-500 group-hover:translate-x-0 group-hover:translate-y-0"
              color="#fff"
              size={30}
            />
          </div>
        </div>
      </div>
      </Link>
    </>
  );
};

const ProjectList: React.FC = () => {
  const { i18n } = useTranslation();
  const [projectsData, setProjectsData] = useState<Project[]>([]);

  useEffect(() => {
    const fetchProjectsData = async () => {
      if (i18n.language) {
        try {
          const response = await fetch(`/locales/${i18n.language}/projects.json`);
          if (!response.ok) {
            throw new Error(`Error fetching projects data: ${response.statusText}`);
          }
          const data = await response.json();
          setProjectsData(data);
        } catch (error) {
          console.error("Error loading projects data:", error);
        }
      }
    };
    fetchProjectsData();
  }, [i18n.language]);

  const sortedProjects = projectsData.sort((a, b) => new Date(b.addedDate).getTime() - new Date(a.addedDate).getTime());

  return (
    <Section position="bottom">
      <div>
        {sortedProjects.map((project, index) => {
          const borderStyle: "top" | "default" = (index === sortedProjects.length - 1) ? "default" : "top";
          return (
            <Item
              key={project.id}
              url={project.id}
              title={project.title}
              border={borderStyle}
              categories={project.categories}
            />
          );
        })}
      </div>
    </Section>
  );
};

export default ProjectList;
