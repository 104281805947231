import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Logo from "../../assets/svg/logo.svg";

interface HeaderProps {
  onToggleCalendly: () => void;
}

const MobileHeader: React.FC<HeaderProps> = ({ onToggleCalendly }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [activeLanguage, setActiveLanguage] = useState<'sv' | 'en'>('en');
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);
  const [colorChange, setColorChange] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const handleClickSV = () => {
    changeLanguage('sv');
    setActiveLanguage('sv');
  };

  const handleClickEN = () => {
    changeLanguage('en');
    setActiveLanguage('en');
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setColorChange(currentScrollY > 100);
    setVisible(currentScrollY < lastScrollY || currentScrollY < 10);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('lock-scroll');
      document.body.classList.add('lock-scroll');
    } else {
      document.documentElement.classList.remove('lock-scroll');
      document.body.classList.remove('lock-scroll');
    }
  }, [menuOpen]);

  return (
    <>
        <header className={`md:hidden w-full px-2.5 py-4 flex justify-center fixed top-0 z-50 transition ease-in-out duration-300 
            ${visible ? 'translate-y-0' : '-translate-y-full'} 
            ${colorChange ? 'bg-[#222222bb] backdrop-blur-lg text-white border-b border-b-[#ffffff05]' : 'bg-transparent text-white border-b-[#ffffff05]'}
            ${menuOpen ? 'bg-[#000] bg-opacity-70 border-b border-b-black' : 'bg-transparent' }
          `}>
            <div className="w-full flex items-center justify-between px-2.5">
                <div className="">
                  <Link to="/" className="flex gap-3 items-center">
                    <img className="w-9 max-md1:w-12" src={Logo} loading="lazy" alt="Logo" />
                    <h1 className="text-md max-md1:text-lg font-manrope lowercase tracking-wide">
                      infinet
                    </h1>
                  </Link>
                </div>

                <div className="flex items-center gap-5 justify-end">
                  <div className="">
                    <ul className="flex overflow-hidden">
                      <li className={`px-1 relative cursor-pointer ${i18n.language === 'sv' ? 'text-white' : 'text-gray-400'}`}
                          onClick={handleClickSV}>
                        <span className="text-sm" aria-hidden="true">SV</span>
                        <div className={`absolute inset-x-0 bottom-0 h-[1.5px] ${i18n.language === 'sv' ? 'bg-white' : 'bg-transparent'} transition-all duration-300`} />
                      </li>

                      <li className={`px-1 relative cursor-pointer ${i18n.language === 'en' ? 'text-white' : 'text-gray-400'}`}
                          onClick={handleClickEN}>
                        <span className="text-sm" aria-hidden="true">EN</span>
                        <div className={`absolute inset-x-0 bottom-0 h-[1.5px] ${i18n.language === 'en' ? 'bg-white' : 'bg-transparent'} transition-all duration-300`} />
                      </li>
                    </ul>
                  </div>
                  <div className="">
                    <button
                      onClick={() => setMenuOpen(!menuOpen)}
                      className="w-6 h-6 aspect-square grid grid-cols-2 grid-rows-2 gap-[2px] group">
                      <div className={`col-span-1 row-span-1 w-full h-full border border-white rounded-sm ${menuOpen ? 'bg-white/85' : ''} transition-all ease-in-out duration-300`}></div>
                      <div className={`col-span-1 row-span-1 w-full h-full border border-white rounded-sm ${menuOpen ? 'bg-white/85' : ''} transition-all ease-in-out duration-300`}></div>
                      <div className={`col-span-1 row-span-1 w-full h-full border border-white rounded-sm ${menuOpen ? 'bg-white/85' : ''} transition-all ease-in-out duration-300`}></div>
                      <div className={`col-span-1 row-span-1 w-full h-full border border-white rounded-sm ${menuOpen ? 'bg-white/85' : ''} transition-all ease-in-out duration-300`}></div>
                    </button>
                  </div>
                </div>
            </div>
        </header>

        <div className={`md:hidden fixed inset-0 backdrop-blur-sm ${menuOpen ? 'bg-[#000] bg-opacity-35 opacity-100 z-40 overflow-hidden' : 'opacity-0 -z-10'} transition-all duration-500`}>
          <div className="w-full h-full flex items-start flex-col justify-center px-10 my-auto">
            <div className="flex flex-col justify-center gap-2 text-white text-3xl tracking-wide uppercase font-base">
              <div className="flex group">
                <div className="h-full w-[2rem] group-hover:w-[2.5rem] transition-all ease-in-out duration-300 flex flex-col justify-end">
                  <h4 className="text-xs leading-none pb-[6px] transition-colors ease-in-out duration-300 group-hover:text-[#f18b1f]">01</h4>
                </div>
                <div className="">
                  <Link onClick={() => setMenuOpen(!menuOpen)} to="/"><span className="leading-none transition-all ease-in-out duration-300 group-hover:text-[#f18b1f]">{t('header.home')}</span></Link>
                </div>
              </div>

              <div className="flex group">
                <div className="h-full w-[2rem] group-hover:w-[2.5rem] transition-all ease-in-out duration-300 flex flex-col justify-end">
                  <h4 className="text-xs leading-none pb-[6px] transition-colors ease-in-out duration-300 group-hover:text-[#f18b1f]">02</h4>
                </div>
                <div className="">
                  <Link onClick={() => setMenuOpen(!menuOpen)} to="/projects"><span className="leading-none transition-all ease-in-out duration-300 group-hover:text-[#f18b1f]">{t('header.projects')}</span></Link>
                </div>
              </div>

              <div className="flex group">
                <div className="h-full w-[2rem] group-hover:w-[2.5rem] transition-all ease-in-out duration-300 flex flex-col justify-end">
                  <h4 className="text-xs leading-none pb-[6px] transition-colors ease-in-out duration-300 group-hover:text-[#f18b1f]">03</h4>
                </div>
                <div className="">
                  <Link onClick={() => setMenuOpen(!menuOpen)} to="/about"><span className="leading-none transition-all ease-in-out duration-300 group-hover:text-[#f18b1f]">{t('header.about')}</span></Link>
                </div>
              </div>

              <div className="flex group">
                <div className="h-full w-[2rem] group-hover:w-[2.5rem] transition-all ease-in-out duration-300 flex flex-col justify-end">
                  <h4 className="text-xs leading-none pb-[6px] transition-colors ease-in-out duration-300 group-hover:text-[#f18b1f]">04</h4>
                </div>
                <div className="">
                  <Link onClick={() => setMenuOpen(!menuOpen)} to="/contact"><span className="leading-none transition-all ease-in-out duration-300 group-hover:text-[#f18b1f]">{t('header.contact')}</span></Link>
                </div>
              </div>

              <div className="flex group">
                <div className="h-full w-[2rem] group-hover:w-[2.5rem] transition-all ease-in-out duration-300 flex flex-col justify-end">
                  <h4 className="text-xs leading-none pb-[6px] transition-colors ease-in-out duration-300 group-hover:text-[#f18b1f]">05</h4>
                </div>
                <div className="">
                  <Link onClick={() => setMenuOpen(!menuOpen)} to="/pricing"><span className="leading-none transition-all ease-in-out duration-300 group-hover:text-[#f18b1f]">{t('header.price')}</span></Link>
                </div>
              </div>
            </div>

            <div className="flex gap-5 text-white mt-10">
              <div className="">
                <a 
                  href="https://outlook.office365.com/owa/calendar/Onetoone@infinet.se/bookings/"
                  target="_blank"
                  className="trans-white-btn text-base tracking-wide border-[#f18b1f] bg-[#f18b1f] hover:bg-[#f18c1fc6] hover:text-white">
                    {t('header.book')}
                </a>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default MobileHeader;
