import { Section } from "../../../components";
import { useTranslation } from "react-i18next";

type ValueProps = {
  titleKey: string;
  textKey: string;
};

const Value = ({ titleKey, textKey }: ValueProps) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-1 flex flex-col gap-1.5 orange-bg">
      <div>
        <h2 className="text-white font-syne text-2xl tracking-wide font-semibold">{t(titleKey)}</h2>
        <p className="white-paragraph text-xs text-white/85">{t(textKey)}</p>
      </div>
      <div>
        <p className="white-paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quaerat suscipit quo non? Laudantium a fugit temporibus. Soluta iste et cum ex ipsam. At, quibusdam provident voluptatem eum natus dolores!</p>
      </div>
    </div>
  );
};

function MissionValues() {
  const { t } = useTranslation();

  return (
    <Section position="default">
      <div className="w-full flex flex-col gap-5 justify-center items-center mb-20">
        <h1 className="default-title text-white text-center">{t('missionAndValuesTitle')}</h1>
        <p className="text-center white-paragraph w-8/12">{t('missionAndValuesDescription')}</p>
      </div>

      <div className="grid grid-cols-2 gap-10">
        <Value titleKey="innovationTitle" textKey="innovationText" />
        <Value titleKey="integrityTitle" textKey="integrityText" />
        <Value titleKey="excellenceTitle" textKey="excellenceText" />
        <Value titleKey="collaborationTitle" textKey="collaborationText" />
      </div>
    </Section>
  );
}

export default MissionValues;
