import Section from "../section/Section";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Contact() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLink = () => {
    navigate('/contact');
  };
   return (
    <Section position="bottom">
      <div className="w-full flex flex-col items-center justify-center rounded-[50px] orange-bg py-[5%] gap-14 max-lg:gap-10 max-md:gap-5">
        <h1 className="text-center max-md:text-sm text-2xl font-syne font-semibold tracking-wide text-white px-[10%]">
          {t('intouch.paragraph')}
        </h1>

        <div className="">
          <button onClick={handleLink} className="button trans-white-btn border-[#f18b1f] bg-[#f18b1f] hover:bg-[#f18c1fc6] hover:text-white">
            {t('intouch.button')}
          </button>
        </div>
      </div>
    </Section>
  );
}

export default Contact;
