import { Section } from "../../../components";
import { useTranslation } from "react-i18next";
import Image from "../../../assets/sample.png";

type StorySectionProps = {
  titleKey: string;
  textKey: string;
  imgSrc: string;
  reverse?: boolean;
};

const StorySection = ({ titleKey, textKey, imgSrc, reverse = false }: StorySectionProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex ${reverse ? 'flex-row-reverse' : ''} gap-10`}>
      <div className="w-2/6">
        <img className="h-full w-full rounded-lg" src={imgSrc} alt={t(titleKey)} />
      </div>
      <div className="flex flex-col justify-center w-4/6">
        <h2 className="text-white font-syne text-2xl tracking-wide font-semibold">{t(titleKey)}</h2>
        <p className="white-paragraph">{t(textKey)}</p>
      </div>
    </div>
  );
};

function OurStory() {
  const { t } = useTranslation();

  return (
    <Section position="top">
      <div className="w-full flex flex-col gap-5 justify-center items-center mb-20">
        <span className="marked-title">{t('craftingExcellence')}</span>
        <h1 className="default-title text-white text-center">{t('ourStory')}</h1>
        <p className="text-center white-paragraph w-8/12">{t('ourStoryDescription')}</p>
      </div>

      <div className="w-full flex flex-col gap-14">
        <StorySection titleKey="introductionTitle" textKey="introductionText" imgSrc={Image} />
        <StorySection titleKey="foundingTitle" textKey="foundingText" imgSrc={Image} reverse />
        <StorySection titleKey="growthTitle" textKey="growthText" imgSrc={Image} />
      </div>
    </Section>
  );
}

export default OurStory;
