import React from "react";
import { Helmet } from "react-helmet";
import { PackageOverview, DetailedPackage, Process, FAQ } from "./index";
import { Banner } from "../../components/index";
import Image from "../../assets/sample.png"

function Packages() {
  return (
    <>
      <Helmet>
        <title>Infinet - Pricing</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta name="author" content="Your Name" />
        <link rel="canonical" href="https://www.yourwebsite.com/" />
      </Helmet>
      <Banner image={Image} />
      <PackageOverview />
      <DetailedPackage />
      <Process />
      <FAQ />
    </>
  );
}

export default Packages;
