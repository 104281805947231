import React, { createContext, useContext, useState, useEffect, ReactNode, FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Testimonial {
  title: string;
  paragraph: string;
  image: string;
  name: string;
}

interface Image {
  banner: string;
  cover: string;
  about: string;
}

interface Stats {
  viewers: string;
  growth: string;
  conversion: string;
}

interface About {
  company: string;
  title: string;
  paragraphOne: string;
  paragraphTwo: string;
  website?: string;
}

interface Steps {
  challange: string;
  solution: string;
  results: string;
}

interface Project {
  id: string;
  title: string;
  image?: Image;
  stats?: Stats;
  about?: About;
  steps?: Steps;
  testimonial?: Testimonial;
}

interface ProjectContextProps {
  projects: Project[];
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const useProjects = (): ProjectContextProps => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
};

export const ProjectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const [projects, setProjects] = useState<Project[]>([]);

  const loadProjects = async (language: string) => {
    try {
      const response = await fetch(`/locales/${language}/projects.json`);
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Failed to load projects data:', error);
    }
  };

  useEffect(() => {
    loadProjects(i18n.language);

    const handleLanguageChange = (lng: string) => {
      loadProjects(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  return (
    <ProjectContext.Provider value={{ projects }}>
      {children}
    </ProjectContext.Provider>
  );
};
