import React, { FC } from "react";

interface BannerProps {
    title: string;
    image: string;
}

const Banner: FC<BannerProps> = (props) => {
    return (
    <>
        <div className="w-full relative flex justify-center items-center max-md:h-[30vh] h-[55vh]">
          <div className="absolute">
            <h1 className="default-title text-white capitalize">{props.title}</h1>
          </div>
          
          <img className="w-full h-full object-cover" src={props.image} alt={`Illustration of ${props.title}`} />
        </div>
      </>
    );
}

export default Banner;