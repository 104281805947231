import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import "../../../styles/global.css";
import Check from "../../../assets/svg/check.svg";
import X from "../../../assets/svg/x.svg";
import productsEn from "../../../data/products_en.json";
import productsSv from "../../../data/products_sv.json";

function DetailedPackage() {
  const { t, i18n } = useTranslation();
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [products, setProducts] = useState(productsEn.products);

  useEffect(() => {
    if (i18n.language === 'sv') {
      setProducts(productsSv.products);
    } else {
      setProducts(productsEn.products);
    }
  }, [i18n.language]);

  const handleButtonClick = (index: number) => {
    setSelectedProductIndex(index);
  };

  const selectedProduct = products[selectedProductIndex];

  return (
    <Section position="default">
      <div className="flex flex-col items-center">
        <div className="mb-16">
          <nav className="text-white flex gap-5">
            {products.map((product, index) => (
              <button
                className={`border font-syne text-sm border-moss-green rounded-full px-5 py-1 transition-all duration-300 ease-in-out hover:bg-moss-green ${
                  selectedProductIndex === index ? "bg-moss-green/75" : ""
                }`}
                key={index}
                onClick={() => handleButtonClick(index)}
              >
                {product.category}
              </button>
            ))}
          </nav>
        </div>

        <div className="w-full flex gap-10">
          <div className="w-4/12 flex flex-col justify-between gap-8">
            <div className="">
              <div className="flex flex-col gap-5">
                <div>
                  <span className="marked-title">{selectedProduct.category}</span>
                </div>
                <h1 className="default-title text-2xl text-white">{selectedProduct.title}</h1>
              </div>

              <div className="mt-2.5">
                <p className="white-paragraph text-sm">{selectedProduct.description}</p>
              </div>
            </div>

            <div className="orange-bg w-full flex flex-col">
              <div className="flex flex-col gap-1.5 h-full w-full justify-center items-center">
                <h1 className="text-white font-syne text-xl font-semibold">{selectedProduct.elseTitle}</h1>
                <p className="text-white text-xs text-center font-poppins">{selectedProduct.elseDescription}</p>
                <div className="mt-5">
                  <a href="https://outlook.office365.com/book/Onetoone@infinet.se/" target="_blank" className="trans-white-btn border-[#f18b1f] bg-[#f18b1f] hover:bg-[#f18c1fc6] hover:text-white">{selectedProduct.elseBtn}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="w-8/12 flex justify-center items-center relative orange-bg gap-5" id="design">
            <div className="w-1/2 h-full p-4 flex flex-col gap-5">
              <div>
                <h2 className="text-white font-semibold">{selectedProduct.package1.title}</h2>
                <h3 className="text-white font-semibold">{selectedProduct.package1.price}</h3>
                <p className="text-white/60 text-xs w-[70%]">{selectedProduct.package1.priceDetails}</p>
              </div>

              <div className="h-[1px] w-full bg-white/20"></div>

              <div>
                <ul className="flex flex-col gap-3">
                  {selectedProduct.package1.includedServices.map((service, index) => (
                    <li key={index} className="flex justify-start items-center gap-2">
                      <div className="bg-moss-green rounded-full h-[17px] max-h-[17px] w-[17px] max-w-[17px] flex items-center justify-center">
                        <img className="w-[10px] h-[10px]" src={Check} alt="" />
                      </div>
                      <h3 className="text-white text-xs font-poppins leading-none">{service}</h3>
                    </li>
                  ))}
                  {selectedProduct.package1.notIncludedServices.map((service, index) => (
                    <li key={index} className="flex justify-start items-center gap-2">
                      <div className="bg-raisan-black/50 rounded-full h-[17px] max-h-[17px] w-[17px] max-w-[17px] flex items-center justify-center">
                        <img className="w-[10px] h-[10px]" src={X} alt="" />
                      </div>
                      <h3 className="text-white text-xs font-poppins leading-none">{service}</h3>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="w-[1px] absolute py-4 h-full">
              <div className="w-full h-full bg-white/20"></div>
            </div>

            <div className="w-1/2 h-full p-4 flex flex-col gap-5">
              <div>
                <h2 className="text-white font-semibold">{selectedProduct.package2.title}</h2>
                <h3 className="text-white font-semibold">{selectedProduct.package2.price}</h3>
                <p className="text-white/60 text-xs w-[70%]">{selectedProduct.package2.priceDetails}</p>
              </div>

              <div className="h-[1px] w-full bg-white/20"></div>

              <div>
                <ul className="flex flex-col gap-3">
                  {selectedProduct.package2.includedServices.map((service, index) => (
                    <li key={index} className="flex justify-start items-center gap-2">
                      <div className="bg-moss-green rounded-full h-[17px] max-h-[17px] w-[17px] max-w-[17px] flex items-center justify-center">
                        <img className="w-[10px] h-[10px]" src={Check} alt="" />
                      </div>
                      <h3 className="text-white text-xs font-poppins leading-none">{service}</h3>
                    </li>
                  ))}
                  {selectedProduct.package2.notIncludedServices.map((service, index) => (
                    <li key={index} className="flex justify-start items-center gap-2">
                      <div className="bg-raisan-black/50 rounded-full h-[17px] max-h-[17px] w-[17px] max-w-[17px] flex items-center justify-center">
                        <img className="w-[10px] h-[10px]" src={X} alt="" />
                      </div>
                      <h3 className="text-white text-xs font-poppins leading-none">{service}</h3>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-center mt-10">
          <p className="text-white/50 text-xs w-1/2 text-center">{t("price.overview.payment")}</p>
        </div>
      </div>
    </Section>
  );
}

export default DetailedPackage;
