// src/components/GoogleMaps.tsx

import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import darkThemeStyles from './mapStyles';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 59.346022108623444,
  lng: 18.034024831444707,
};

const mapOptions = {
  styles: darkThemeStyles,
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const GoogleMaps: React.FC = () => {
  const [placeInfo, setPlaceInfo] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId: "ChIJZyqLCjmdX0YRycnu8rw_HaI" }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPlaceInfo(place);
          console.log("Place details fetched successfully", place);
        } else {
          console.error("Error fetching place details:", status, place);
        }
      });
    }
  }, []);

  const customIcon = {
    url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f18b1f" stroke="none">
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
      </svg>
    `),
    scaledSize: new window.google.maps.Size(40, 40),
  };

  const handleMarkerClick = () => {
    if (placeInfo) {
      const url = `https://www.google.com/maps/search/?api=1&query=${placeInfo.name}&query_place_id=${placeInfo.place_id}`;
      window.open(url, "_blank");
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      options={mapOptions}
    >
      {placeInfo && (
        <Marker
          position={center}
          icon={customIcon}
          onClick={handleMarkerClick}
        />
      )}
    </GoogleMap>
  );
};

export default GoogleMaps;
