import React from "react";
import { Section } from "../../../components";
import { IoIosCheckboxOutline } from "react-icons/io";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { PiMountains } from "react-icons/pi";

interface StepsProps {
    challange: string;
    solution: string;
    results: string;
}

function Steps( props: StepsProps ) {
    return(
        <>
            <Section position="default">
                <div className="w-full max-md:flex max-md:flex-col grid grid-cols-3 max-md:gap-10 gap-20">
                    <div className="flex flex-col gap-3 col-span-1">
                        <div className="flex items-center gap-3 text-moss-green">
                            <PiMountains size={30} />
                            <h1 className="font-syne text-2xl tracking-wide text-white">Challenge</h1>
                        </div>

                        <p className="white-paragraph">
                            {props.challange}
                        </p>
                    </div>

                    <div className="flex flex-col gap-3 col-span-1">
                        <div className="flex items-center gap-3 text-moss-green">
                            <IoExtensionPuzzleOutline size={30} />
                            <h1 className="font-syne text-2xl tracking-wide text-white">Solution</h1>
                        </div>

                        <p className="white-paragraph">
                            {props.solution}
                        </p>
                    </div>

                    <div className="flex flex-col gap-3 col-span-1">
                        <div className="flex items-center gap-3 text-moss-green">
                            <IoIosCheckboxOutline size={30} />
                            <h1 className="font-syne text-2xl tracking-wide text-white">Results</h1>
                        </div>

                        <p className="white-paragraph">
                            {props.results}
                        </p>
                    </div>
                </div>
            </Section>
        </>
    );
}

export default Steps;