import { Section } from "../../../components";
import { useTranslation } from "react-i18next";
import Client from "../../../assets/svg/marketing.svg";

interface ItemProps {
  image: string;
}

const Item: React.FC<ItemProps> = (props) => {
  return (
    <div className="col-span-1 row-span-1 flex justify-center items-center">
      <img className="h-20 max-lg:h-16 max-md:h-14" src={props.image} alt="partner logo" />
    </div>
  );
};

function Record() {
  const { t } = useTranslation();
  return (
    <Section position="default">
      <div className="flex w-full max-md:flex-col max-md:gap-8">
        <div className="w-1/2 flex flex-col gap-8 max-md:w-full">
          <div className="flex flex-col max-md:items-center justify-center max-md:gap-2 gap-5">
            <div>
              <span className="marked-title">{t('home.record.marked')}</span>
            </div>
            <h1 className="default-title text-white">{t('home.record.title')}</h1>
          </div>

          <div className="max-md:pr-0 max-md:text-center">
            <p className="white-paragraph">
              {t('home.record.paragraph')}
            </p>
          </div>
        </div>

        <div className="w-1/2 flex justify-center items-center max-md:w-full">
          <div className="grid grid-cols-3 grid-rows-2 max-md:gap-x-8 gap-x-5 max-md:gap-y-4 gap-y-8">
            <Item image={Client} />
            <Item image={Client} />
            <Item image={Client} />

            <Item image={Client} />
            <Item image={Client} />
            <Item image={Client} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Record;
