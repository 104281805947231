import { Section } from "../../../components";
import { useTranslation } from "react-i18next";
import { MdArrowOutward } from "react-icons/md";
import Image from "./../../../assets/img/what-we-do.jpeg";
import { useNavigate } from "react-router-dom";

function Welcome() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLink = () => {
    navigate('/about');
  };

  return (
    <Section position="top" scrollTo="about-one">
      <div className="flex max-md1:flex-col max-md1:justify-center">
        <div className="max-md1:w-full w-1/2 flex flex-col justify-center items-end">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col max-md1:items-center justify-center max-md1:gap-2 gap-5">
              <div>
                <span className="marked-title">{t('home.welcome.marked')}</span>
              </div>
              <h1 className="default-title text-white">{t('home.welcome.title')}</h1>
            </div>

            <div className="flex max-md1:items-center flex-col gap-12">
              <p className="white-paragraph max-md1:pr-0 max-md1:text-center pr-[10%]">
                {t('home.welcome.paragraph')}
              </p>
              <div className="">
                <button onClick={handleLink} className="trans-white-btn">
                  {t('home.welcome.button')}
                  <MdArrowOutward />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 max-md1:hidden flex justify-end">
          <img className="w-[90%] h-[30rem] rounded-[50px] object-cover" src={Image} loading="lazy" alt="" />
        </div>
      </div>
    </Section>
  );
}

export default Welcome;
