import { Section } from "../../../components";

interface Props {
    title: string;
    image: string;
    name: string;
    paragraph: string;
}

function Testimonial( props: Props ) {
    return (
        <Section position="default">
            <div className="w-full max-md:px-0 px-10 max-md:py-0">
                <div className="flex w-full justify-center px-[25%] max-md:px-0">                    
                    <div className="flex items-center justify-center flex-col gap-6">
                        <div className="">
                            <img 
                                src={props.image} 
                                alt={`Illustration of ${props.image}`}
                                loading="lazy"
                                className="rounded-full w-20 h-20 object-cover"
                            />
                        </div>

                        <div className="">
                            <p className="white-paragraph max-md:text-sm text-xl text-white text-center">{props.paragraph}</p>
                        </div>

                        <div className="text-center text-white font-poppins tracking-wide">
                            <b className="">{props.name}</b>
                            <p className="text-xs">{props.title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default Testimonial;