import { Section } from "../../../components";

interface StatsProps {
    viewers: string;
    growth: string;
    conversion: string;
}

function Stats( props: StatsProps ) {
    return (
        <Section position="default">
            <div className="flex w-full max-md:px-2 px-10 max-md:py-12 py-14 orange-bg rounded-[20px]">
                <div className="w-full grid grid-cols-3">
                    <div className="col-span-1 flex flex-col text-center">
                        <h1 className="max-md:text-[22px] max-md:leading-none text-[40px] font-poppins font-bold text-white">{props.viewers}</h1>
                        <p className="white-paragraph max-md:text-[9px]">Visitors to website</p>
                    </div>

                    <div className="col-span-1 flex flex-col text-center">
                        <h1 className="max-md:text-[22px] max-md:leading-none text-[40px] font-poppins font-bold text-white">{props.growth}</h1>
                        <p className="white-paragraph max-md:text-[9px]">Increase in page views</p>
                    </div>

                    <div className="col-span-1 flex flex-col text-center">
                        <h1 className="max-md:text-[22px] max-md:leading-none text-[40px] font-poppins font-bold text-white">{props.conversion}</h1>
                        <p className="white-paragraph max-md:text-[9px]">Visitors to website</p>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default Stats;