import React from "react";
import LoadScriptWrapper from "../../../components/google-maps/LoadScriptWrapper";

function Map() {
  return (
    <div className="w-full h-[45vh] flex justify-center items-center">
      <LoadScriptWrapper />
    </div>
  );
}

export default Map;

