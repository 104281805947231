import { FC } from "react";

interface BannerProps {
  image: string;
}

const Banner: FC<BannerProps> = ({ image }) => {
  return (
    <>
      <div className="w-full max-md:h-[30vh] h-[55vh] bg-black">
        <img className="w-full h-full object-cover opacity-55" src={image} loading="lazy" alt="Illustration of the current page you are navigated to" />
      </div>
    </>
  );
};

export default Banner;
