import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { scrollTo } from "../../utils/scrollTo";
import Logo from "../../assets/svg/logo.svg";

interface HeaderProps {
  onToggleCalendly: () => void;
}

const Header: React.FC<HeaderProps> = ({ onToggleCalendly }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [activeLanguage, setActiveLanguage] = useState<'sv' | 'en'>('en');
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);
  const [colorChange, setColorChange] = useState(false);
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const handleClickSV = () => {
    changeLanguage('sv');
    setActiveLanguage('sv');
  };

  const handleClickEN = () => {
    changeLanguage('en');
    setActiveLanguage('en');
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setColorChange(currentScrollY > 100);
    setVisible(currentScrollY < lastScrollY || currentScrollY < 10);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header className={`max-md1:hidden w-full flex justify-center fixed top-0 z-50 transition ease-in-out duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'} ${colorChange ? 'bg-[#222222bb] backdrop-blur-lg text-white border-b border-[#ffffff05]' : 'bg-transparent text-white border-[#ffffff05]'}`}>
      <div className="w-full px-[15%] py-4 md:px-0 md:w-11/12 xl:w-10/12 mx-auto max-w-[1550px]">
        <div className="w-full flex justify-between items-center">
          <Link to="/" className="flex gap-3 items-center">
            <img className="w-16" src={Logo} loading="lazy" alt="Logo" />
            <h1 className="text-[22px] font-manrope lowercase tracking-wide">
              infinet
            </h1>
          </Link>

          <div className="flex gap-8 items-center font-syne leading-[16px] tracking-wide font-medium">
              <>
                <Link to="/">
                  <span>{t('header.home')}</span>
                </Link>

                <Link to="/projects">
                  <span>{t(`header.projects`)}</span>
                </Link>

                <Link to="/about">
                  <span>{t('header.about')}</span>
                </Link>

                <Link to="/pricing">
                  <span>{t('header.price')}</span>
                </Link>

                <Link to="/contact">
                  <span>{t('header.contact')}</span>
                </Link>
              </>
          </div>

          <div className="flex gap-5">
            <div className="flex items-center">
              <ul className="flex overflow-hidden">
                <li className={`px-1 relative cursor-pointer ${i18n.language === 'sv' ? 'text-white' : 'text-gray-400'}`}
                    onClick={handleClickSV}>
                  <span aria-hidden="true">SV</span>
                  <div className={`absolute inset-x-0 bottom-0 h-0.5 ${i18n.language === 'sv' ? 'bg-white' : 'bg-transparent'} transition-all duration-300`} />
                </li>

                <li className={`px-1 relative cursor-pointer ${i18n.language === 'en' ? 'text-white' : 'text-gray-400'}`}
                    onClick={handleClickEN}>
                  <span aria-hidden="true">EN</span>
                  <div className={`absolute inset-x-0 bottom-0 h-0.5 ${i18n.language === 'en' ? 'bg-white' : 'bg-transparent'} transition-all duration-300`} />
                </li>
              </ul>
            </div>

            <div className="">
              <a 
                href="https://outlook.office365.com/owa/calendar/Onetoone@infinet.se/bookings/"
                target="_blank"
                className="trans-white-btn border-[#f18b1f] bg-[#f18b1f] hover:bg-[#f18c1fc6] hover:text-white">
                {t('header.book')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
