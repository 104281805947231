import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/svg/logo.svg";
import { useTranslation } from "react-i18next";
import Instagram from "../../assets/svg/socials/white-instagram.svg";
import LinkedIn from "../../assets/svg/socials/white-linkedin.svg";
import Dribbble from "../../assets/svg/socials/white-dribbble.svg";
import Behance from "../../assets/svg/socials/white-behance.svg";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { t } = useTranslation();
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const newYear = new Date().getFullYear();
        if (newYear !== currentYear) {
          setCurrentYear(newYear);
        }
      },
      1000 * 60 * 60,
    ); // Update every hour

    return () => clearInterval(intervalId);
  }, [currentYear]);

  return (
    <footer
      className="w-full flex flex-col items-center
         justify-center bg-[#181818]"
    >
      <div className="max-md:hidden w-full py-[6%] md:w-11/12 xl:w-10/12 mx-auto max-w-[1550px]">
        <div className="flex flex-col">
          <div className="w-full h-full flex justify-between items-start">
            <div className="flex h-full flex-col justify-center items-center">
              <div className="items-center h-full flex flex-col">
                <img className="w-32" src={Logo} alt="" />
                <h3 className="font-manrope text-white text-[28px] tracking-wide">
                  infinet
                </h3>
              </div>
            </div>

            <div className="flex gap-32">
              <div className="flex flex-col gap-5">
                <h3 className="text-[28px] font-syne font-semibold text-white leading-[28px]">
                  {t('footer.navigation.title')}
                </h3>
                <div className="flex flex-col gap-4">
                  <Link
                    to="/"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.navigation.home')}</span>
                  </Link>
                  <Link
                    to="/projects"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.navigation.project')}</span>
                  </Link>
                  <Link
                    to="/about"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.navigation.about')}</span>
                  </Link>
                  <Link
                    to="/contact"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.navigation.contact')}</span>
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <h3 className="text-[28px] font-syne font-semibold text-white leading-[28px]">
                  {t('footer.services.title')}
                </h3>
                <div className="flex flex-col gap-4">
                  <Link
                    to="/about"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.services.website')}</span>
                  </Link>
                  <Link
                    to="/about"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.services.hosting')}</span>
                  </Link>
                  <Link
                    to="/about"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.services.design')}</span>
                  </Link>
                  <Link
                    to="/about"
                    className="font-syne text-white text-[18px] leading-[18px]"
                  >
                    <span>{t('footer.services.marketing')}</span>
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <h3 className="text-[28px] font-syne font-semibold text-white leading-[28px]">
                  {t('footer.connect.title')}
                </h3>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 items-center">
                    <a
                      href="https://www.instagram.com/infinet.agency/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={Instagram} alt="" />
                    </a>

                    <a 
                      href="https://www.behance.net/" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={Behance} alt="" />
                    </a>

                    <a 
                      href="https://dribbble.com/" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={Dribbble} alt="" />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/infinet-group-ab/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={LinkedIn} alt="" />
                    </a>
                  </div>

                  <div className="flex flex-col gap-1">
                    <a href="mailto:contact@infinet.agency">
                      <span className="text-white font-poppins text-[16px] leading-[18px]">
                        contact@infinet.agency
                      </span>
                    </a>
                    <a href="tel:0046701467411">
                      <span className="text-white font-poppins text-[16px] leading-[18px]">
                        +46 70 146 74 11
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center py-4">
        <p className="font-poppins tracking-wider text-white max-md:text-[8px] text-[12px] opacity-15 uppercase">
          &copy; {currentYear} {t('footer.copywrite')}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
