import React from "react";
import { Section } from "../../../components";
import Image from "./../../../assets/img/another-one.jpeg";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function About() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const handleLink = () => {
    navigate('/about');
  };
  
  return (
    <Section position="default">
      <div className="orange-bg rounded-[50px] flex items-center max-md:py-8 py-16 max-md:px-6 px-16 gap-16">
        <div className="w-1/2 h-full max-lg:hidden max-md:hidden">
          <img className="w-full h-[32rem] rounded-[50px] object-cover" loading="lazy" src={Image} alt="" />
        </div>

        <div className="w-1/2 flex flex-col justify-center max-lg:items-center gap-8 max-lg:w-full">
          <div className="flex flex-col max-lg:items-center justify-center max-lg:gap-2 gap-5">
            <div>
              <span className="marked-title max-lg:text-center">{t('home.about.marked')}</span>
            </div>
            <h1 className="default-title text-white max-lg:text-center">{t('home.about.title')}</h1>
          </div>

          <p className="white-paragraph max-lg:text-center">
          {t('home.about.paragraphOne')}
          <br /><br />
          {t('home.about.paragraphTwo')}
          </p>

          <div className="">
            <button onClick={handleLink} className="trans-white-btn">
              {t('home.about.button')}
              <MdArrowOutward />
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default About;
