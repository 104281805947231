import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Image from "../../../assets/sample.png";
import { Section } from "../../../components";
import { FiPhone, FiMail } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import Anton from "../../../assets/img/staff/anton-min.jpg";

interface ItemProps {
  image: string;
  name: string;
  title: string;
  phone: string;
  linkedin: string;
  mail: string;
}

const Item: FC<ItemProps> = ({ image, name, title, phone, mail, linkedin }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col col-span-1 row-span-1 gap-4">
      <img className="w-full max-md:h-[16rem] h-[20rem] rounded-[20px] object-cover" src={image} loading="lazy" alt="" />

      <div className="flex flex-col gap-2">
        <div className="">
          <h1 className="text-white text-2xl font-syne leading-5 max-md:text-xl">{name}</h1>
          <h2 className="text-moss-green text-sm font-syne max-md:text-xs">{title}</h2>
        </div>

        <div className="flex gap-2">
          <a className="group" href={`tel:${phone}`}>
            <FiPhone
              className="duration-300 transition-all ease-in-out group-hover:scale-90"
              size={14}
              color="#fff"
            />
          </a>

          <a className="group" href={`mailto:${mail}`}>
            <FiMail
              className="duration-300 transition-all ease-in-out group-hover:scale-90"
              size={14}
              color="#fff"
            />
          </a>

          <a className="group" href={linkedin} target="_blank" rel="noreferrer">
            <FaLinkedinIn
              className="duration-300 transition-all ease-in-out group-hover:scale-90"
              size={14}
              color="#fff"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

function Team() {
  const { t } = useTranslation();
  return (
    <>
      <Section position="bottom">
        <div className="w-full flex justify-center">
          <div className="grid grid-cols-4 gap-x-16 max-md:grid-cols-2 max-md:gap-x-6 max-md:gap-y-10">
            <Item
              image={t('team.userOne.image')}
              name={t('team.userOne.name')}
              title={t('team.userOne.title')}
              phone={t('team.userOne.phone')}
              linkedin={t('team.userOne.linkedin')}
              mail={t('team.userOne.mail')}
            />
            <Item
              image={t('team.userTwo.image')}
              name={t('team.userTwo.name')}
              title={t('team.userTwo.title')}
              phone={t('team.userTwo.phone')}
              linkedin={t('team.userTwo.linkedin')}
              mail={t('team.userTwo.mail')}
            />
            <Item
              image={t('team.userThree.image')}
              name={t('team.userThree.name')}
              title={t('team.userThree.title')}
              phone={t('team.userThree.phone')}
              linkedin={t('team.userThree.linkedin')}
              mail={t('team.userThree.mail')}
            />
            <Item
              image={t('team.userFour.image')}
              name={t('team.userFour.name')}
              title={t('team.userFour.title')}
              phone={t('team.userFour.phone')}
              linkedin={t('team.userFour.linkedin')}
              mail={t('team.userFour.mail')}
            />
          </div>
        </div>
      </Section>
    </>
  );
}

export default Team;
