// src/contexts/LanguageContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import i18n from '../i18n';

interface LanguageContextProps {
  language: 'en' | 'sv';
  changeLanguage: (lang: 'en' | 'sv') => void;
}

export const LanguageContext = createContext<LanguageContextProps>({
  language: 'en',
  changeLanguage: () => {},
});

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<'en' | 'sv'>('en');

  const changeLanguage = (lang: 'en' | 'sv') => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
