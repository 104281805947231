import React from "react";
import { Banner } from "../../components/index";
import { ProjectList, Title } from "./index";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Image from "../../assets/img/projects-banner.jpeg";

function Projects() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Infinet - {t('projects.title')}</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <link rel="canonical" href={`https://infinet.agency/projects/`} />
      </Helmet>
      <Banner image={Image} />
      <Title titlebadge={t('projects.marked')} title={t('projects.title')} />
      <ProjectList />
    </>
  );
}

export default Projects;