import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import Arrow from "../../../assets/svg/arrow.svg";
import "../../../styles/global.css";

// Define the type for a FAQ item
interface FAQItem {
  question: string;
  answer: string;
}

function FAQ() {
  const { i18n, t } = useTranslation();
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);
  const [questions, setQuestions] = useState<FAQItem[]>([]);

  const toggleQuestion = (index: number) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  useEffect(() => {
    const loadQuestions = async () => {
      const language = i18n.language === "sv" ? "sv" : "en";
      const questionsData = await import(`../../../data/faq_${language}.json`);
      setQuestions(questionsData.default);
    };

    loadQuestions();
  }, [i18n.language]);

  return (
    <Section position="default">
      <div className="w-full flex flex-col items-center">
        <div className="flex flex-col gap-5 items-center mb-20">
          <div className="">
            <span className="marked-title">FAQ</span>
          </div>
          <h1 className="default-title text-white">Frequently Asked Questions</h1>
        </div>

        {questions.map((item, index) => {
          const borderClass = index === questions.length - 1 ? "border-y" : "border-t";

          return (
            <div key={index} className={`flex flex-col w-full ${borderClass} border-white/25 py-3.5 px-5`}>
              <div
                className="w-full flex justify-between items-center cursor-pointer"
                onClick={() => toggleQuestion(index)}
              >
                <h1
                  className={`font-syne text-2xl transition-colors duration-500 ${
                    openQuestion === index ? "text-moss-green" : "text-white"
                  } hover:text-moss-green`}
                >
                  {item.question}
                </h1>
                <div
                  className={`transform transition-transform duration-500 ${
                    openQuestion === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <img src={Arrow} alt="Arrow icon" />
                </div>
              </div>
              <div
                className={`default-paragraph w-1/2 text-white/85 overflow-hidden transition-max-height duration-500 ease-in-out ${
                  openQuestion === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                {item.answer}
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

export default FAQ;
