import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/global.css";
import VideoWEBM from "../../../assets/video/hero.webm";
import VideoMP4 from "../../../assets/video/hero.mp4";
import PlaceholderImage from "../../../assets/sample.png"; // Ensure the correct path

function Hero() {
  const { t } = useTranslation();
  const [isLowPowerMode, setIsLowPowerMode] = useState(false);

  useEffect(() => {
    async function checkBatteryStatus() {
      let lowPowerMode = false;

      // Check for the Battery Status API
      if ((navigator as any).getBattery) {
        try {
          const battery = await (navigator as any).getBattery();
          lowPowerMode = !battery.charging && battery.level < 0.15;
          battery.addEventListener('chargingchange', () => {
            setIsLowPowerMode(!battery.charging && battery.level < 0.15);
          });
        } catch (error) {
          console.error("Battery API error:", error);
        }
      }

      // Fallback to check navigator.connection
      const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
      if (connection) {
        if (connection.saveData) {
          lowPowerMode = true;
        }
      }

      setIsLowPowerMode(lowPowerMode);
    }

    checkBatteryStatus();
  }, []);

  return (
    <div
      className="relative w-full h-screen flex items-center justify-center bg-[#000]"
      id="hero"
    >
      {isLowPowerMode ? (
        <img
          src={PlaceholderImage}
          alt="Placeholder"
          className="absolute w-full h-full object-cover opacity-65"
        />
      ) : (
        <video
          className="absolute w-full h-full object-cover opacity-65"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={VideoWEBM} type="video/webm" className="md:hidden" />
          <source src={VideoMP4} type="video/mp4" className="max-md:hidden" />
        </video>
      )}

      <div className="absolute top-1/2 left-1/2 w-full transform -translate-x-1/2 -translate-y-1/2 text-center text-white max-md:px-0 max-md:w-11/12 max-xl:w-10/12 mx-auto">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-3">
            <h4 className="text-center font-manrope max-md:text-[18px] text-[24px] leading-[5px] tracking-wide text-white">
              infinet
            </h4>
            <h1 className="font-syne max-md:text-[25px] text-[50px] font-medium max-md:leading-[35px] leading-[60px] tracking-wider text-center text-white">
              {t('home.hero.titleOne')} <span className="orange-text">{t('home.hero.titleTwo')} </span>
              <br className="max-md:hidden"></br>{t('home.hero.titleThree')}
            </h1>
            <h4 className="text-center font-syne max-md:text-[16px] text-[24px] max-md:leading-[16px] leading-[24px] tracking-wide">
              {t('home.hero.subtitle')}
            </h4>
          </div>

          <div className="flex justify-center gap-5">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
