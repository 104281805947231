import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation, BrowserRouter as Router } from "react-router-dom";
import { Footer, Header, MobileHeader, Contact
 } from "./components/index";
import { Home, Projects, ContactUs, About, Packages } from "./pages/index";
import { initGA, logPageView } from "./utils/analytics";
import { initTagManager } from "./utils/tagManager";
import ScrollToTop from "./utils/scrollToTop";
import { ProjectProvider } from "./pages/single-project/projectContext";
import { LanguageProvider } from "./utils/languageContext";
import {Project} from "./pages/index";
import "./i18n";
import "./styles/tailwind.css";
import "./styles/scrollbar.css";
import "./styles/global.css";

const GOOGLE_ANALYTIC_API = process.env.REACT_APP_GOOGLE_ANALYTIC;
const GOOGLE_TAG_MANAGER_API = process.env.REACT_APP_TAG_MANAGER;

const App: React.FC = () => {
  const location = useLocation();
  const hideContact = location.pathname.startsWith("/contact") || location.pathname.startsWith("/projects");
  const [isCalendlyVisible, setIsCalendlyVisible] = useState(false);

  const handleToggleCalendly = () => {
    setIsCalendlyVisible(!isCalendlyVisible);
  };

  useEffect(() => {
    initGA(`${GOOGLE_ANALYTIC_API}`);
    logPageView();

    initTagManager(`${GOOGLE_TAG_MANAGER_API}`);
  }, []);

  return (
    <>
      <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.infinet.agency/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Projects",
                "item": "https://www.infinet.agency/projects"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Moët Hennessy",
                "item": "https://www.infinet.agency/projects/moet-hennessy"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Green Group",
                "item": "https://www.infinet.agency/projects/green-group"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Hofbro Fastigheter",
                "item": "https://www.infinet.agency/projects/hofbro-fastigheter"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Estatement Group",
                "item": "https://www.infinet.agency/projects/estatement-group"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "AGV Nordics",
                "item": "https://www.infinet.agency/projects/agv-nordics"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Köttfabriken",
                "item": "https://www.infinet.agency/projects/kottfabriken"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Whylessisnotmore",
                "item": "https://www.infinet.agency/projects/whylessisnotmore"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "John Lindholm",
                "item": "https://www.infinet.agency/projects/john-lindholm"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Esports Talent Agency",
                "item": "https://www.infinet.agency/projects/esports-talent-agency"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Elektropartner",
                "item": "https://www.infinet.agency/projects/elektropartner"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "JobDeal",
                "item": "https://www.infinet.agency/projects/jobdeal"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Vikingabud Transport",
                "item": "https://www.infinet.agency/projects/vikingabud-transport"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Atell",
                "item": "https://www.infinet.agency/projects/atell"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Mercurius",
                "item": "https://www.infinet.agency/projects/mercurius"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "OY Drinks",
                "item": "https://www.infinet.agency/projects/oy-drinks"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Carmés Clothing",
                "item": "https://www.infinet.agency/projects/carmes-clothing"
              }
            ]
          }
        `}
      </script>
      <meta property="og:title" content="Infinet Group" />
      <meta property="og:description" content="Description of your website" />
      <meta property="og:url" content="" />
      <meta property="og:image" content="path_to_image" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Infinet Group" />
      <meta name="twitter:description" content="Your page description" />
      <meta name="twitter:image" content="https://www.yourwebsite.com/twitter-image.jpg" />
      <meta name="author" content="Infinet Agency AB" />
      <link rel="canonical" href={`https://infinet.agency/`} />
      </Helmet>
        <LanguageProvider>
          <ProjectProvider>
            <ScrollToTop />
            <MobileHeader onToggleCalendly={handleToggleCalendly} />
            <Header onToggleCalendly={handleToggleCalendly} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:projectId" element={<Project />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/pricing" element={<Packages />} />
              <Route path="/about" element={<About />} />
            </Routes>
            {!hideContact && <Contact />}
            <Footer />
          </ProjectProvider>
        </LanguageProvider>
    </>
  );
}

export default App;
