import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import GoogleMaps from './googleMaps';

const LoadScriptWrapper: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB3mWDGssjInM60nozw00NQJ_vPGHanSPU",
    libraries: ["places"]
  });

  if (loadError) {
    return <div>Failed to load Google Maps. Please try again later.</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return <GoogleMaps />;
};

export default LoadScriptWrapper;
