import React from "react";
import { Helmet } from "react-helmet";
import { OurStory, MissionValues, Services } from "./index";
import { Banner } from "./../../components/index";
import Image from "../../assets/img/about-banner.jpeg";

function About() {
  return (
    <>
      <Helmet>
        <title>Infinet - About</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta property="og:image" content={Image} />
        <link rel="canonical" href={`https://infinet.agency/about/`} />
      </Helmet>
      <Banner image={Image} />
      <OurStory />
      <MissionValues />
      <Services />
    </>
  );
}

export default About;
