import React, { FC } from "react";
import { Section } from "../../../components";
import "../../../styles/tailwind.css";

interface TitleProps {
  titlebadge: string;
  title: string;
}

const Title: FC<TitleProps> = ({ title, titlebadge }) => {
  return (
    <>
    <div className="max-md:hidden">
      <Section position="top">
        <div className="w-full flex flex-col justify-center items-center gap-4">
          <h3 className="marked-title">{titlebadge}</h3>
          <h1 className="default-title text-white">{title}</h1>
        </div>
      </Section>
    </div>

    <div className="md:hidden pt-[20%]">
      <div className="w-full flex flex-col justify-center items-center gap-4">
        <h3 className="marked-title">{titlebadge}</h3>
        <h1 className="default-title text-white">{title}</h1>
      </div>
    </div>
    </>
  );
};

export default Title;
