import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useProjects } from "./projectContext";
import { useTranslation } from 'react-i18next';
import { Banner, About, Steps, Stats, Testimonial } from "./index";
import { Contact } from "../../components";

const Project: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { projects } = useProjects();
  const project = projects.find(p => p.id === projectId);
  const { t } = useTranslation();

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>Infinet - {project.title}</title>
        <meta name="description" content="Description of your website" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta property="og:image" content={project.image?.cover} />
        <link rel="canonical" href={`https://infinet.agency/projects/${project.id}`} />
      </Helmet>
      <Banner 
        title={t(`${project.title}`)}
        image={project.image?.banner || ""}
      />
      <About 
        image={project.image?.cover}
        company={project.about?.company}
        title={project.about?.title}
        paragraphOne={project.about?.paragraphOne}
        paragraphTwo={project.about?.paragraphTwo}
        website={project.about?.website}
      />
      <Steps 
        challange={t(`${project.steps?.challange}`)}
        solution={t(`${project.steps?.solution}`)}
        results={t(`${project.steps?.results}`)}
      />
      <Stats 
        viewers={t(`${project.stats?.viewers}`)}
        growth={t(`${project.stats?.growth}`)}
        conversion={t(`${project.stats?.conversion}`)}
      />
      {project.testimonial && (
        <Testimonial 
          title={t(`${project.testimonial.title}`)}
          paragraph={t(`${project.testimonial.paragraph}`)}
          image={`${project.testimonial.image}`}
          name={project.testimonial.name}
        />
      )}
      <Contact />
    </>
  );
}

export default Project;
