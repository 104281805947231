import { Section } from "../../../components";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  return (
    <Section position="bottom">
        <div className="w-full flex flex-col gap-5 justify-center items-center">
          <h1 className="default-title text-white text-center">Our services</h1>
          <p className="text-center white-paragraph w-8/12">We provide a full spectrum of web services designed to help businesses thrive in the digital age. From initial concept to final implementation, our tailored solutions are designed to meet your unique needs.</p>
        </div>
    </Section>
  );
}

export default Services;
