import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Section } from "../../../components";
import Image from "../../../assets/sample.png";
import { MdArrowOutward } from "react-icons/md";
import { useTranslation } from "react-i18next";

interface Project {
  id: string;
  title: string;
  description: string;
  image: {
    cover: string;
    banner: string;
    about: string;
  };
  about: {
    company: string;
    title: string;
    paragraphOne: string;
    paragraphTwo: string;
    website?: string;
  };
  steps: {
    challange: string;
    solution: string;
    results: string;
  };
  stats: {
    viewers: string;
    growth: string;
    conversion: string;
  };
  testimonial: {
    title: string;
    paragraph: string;
    image: string;
    name: string;
  };
  addedDate: string;
  categories: string[];
}

interface ItemProps {
  url: string;
  image: string;
  title: string;
  paragraph: string;
  categories?: string[];
}

const Item: FC<ItemProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Link to={`/projects/${props.url}`} className="col-span-1 row-span-1 flex flex-col max-lg:gap-4 max-md:gap-2 gap-10 group">
      <div className="w-full max-lg2:h-[22rem] max-lg:h-[18rem] max-md:h-[20rem] h-[30rem] overflow-hidden rounded-[20px] group">
        <img className="w-full h-full object-cover transition-all duration-300 group-hover:scale-105 hover:cursor-pointer" src={props.image} alt={`Illustration of project ${props.title}`} />
      </div>

      <div className="flex flex-col max-lg:gap-1 max-md:gap-0 max-md:pb-1 max-md:pt-1.5 gap-4">
        <div className="w-full flex justify-between items-center">
          <h1 className="default-title text-white font-semibold font-syne tracking-wide">
            {props.title}
          </h1>

          <div className="relative overflow-hidden flex justify-center items-center w-10 h-10">
            <MdArrowOutward
              className="absolute group-hover:translate-x-8 group-hover:-translate-y-8 transition-all ease-in-out duration-500"
              color="#fff"
              size={30}
            />
            <MdArrowOutward
              className="absolute -translate-x-8 translate-y-8 transition-all ease-in-out duration-500 group-hover:translate-x-0 group-hover:translate-y-0"
              color="#fff"
              size={30}
            />
          </div>
        </div>

        <div className="">
          <p className="white-paragraph pr-6">{props.paragraph}</p>
        </div>
      </div>

      <div className="flex max-lg:gap-2 max-md:gap-3 gap-6 flex-wrap">
        {props.categories &&
          props.categories.map((category, index) => (
            <div key={index} id={category}>
              <span className="max-lg:text-[9px] max-md:text-[8px] text-[12px] font-syne leading-[12px] text-white max-md:border border-2 border-moss-green px-4 py-1 rounded-full transition-all duration-300 hover:bg-moss-green hover:text-black hover:cursor-pointer">
                {t(`${category}`)}
              </span>
            </div>
          ))}
      </div>
    </Link>
  );
};

const SectionSix: FC = () => {
  const { t, i18n } = useTranslation();
  const [projectsData, setProjectsData] = useState<Project[]>([]);

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await fetch(`/locales/${i18n.language}/projects.json`);
        if (!response.ok) {
          throw new Error(`Error fetching projects data: ${response.statusText}`);
        }
        const data = await response.json();
        setProjectsData(data);
      } catch (error) {
        console.error("Error loading projects data:", error);
      }
    };
    fetchProjectsData();
  }, [i18n.language]);

  const sortedProjects = projectsData.sort((a, b) => new Date(b.addedDate).getTime() - new Date(a.addedDate).getTime());
  const latestProjects = sortedProjects.slice(0, 4);

  return (
    <Section position="default" scrollTo="projects">
      <div className="w-full flex flex-col max-md:gap-8 gap-20">
          <div className="flex flex-col max-md:items-center justify-center items-center max-md:gap-2 gap-5">
            <div>
              <span className="marked-title">{t('home.projects.marked')}</span>
            </div>
            <h1 className="default-title text-white">{t('home.projects.title')}</h1>
          </div>

        <div className="grid max-md:grid-cols-1 grid-cols-2 grid-rows-2 gap-x-16 max-md:gap-y-10 gap-y-20">
          {latestProjects.map((project) => (
            <Item
              key={project.id}
              url={project.id}
              image={project.image.cover || Image}
              title={project.title}
              paragraph={project.description || "No description available"}
              categories={project.categories}
            />
          ))}
        </div>

        <div className="flex justify-center pt-10 max-md:pt-5">
          <Link to="/projects">
            <button className="trans-white-btn">
              {t('home.projects.button')}
              <MdArrowOutward />
            </button>
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default SectionSix;
