import React, { ReactNode, FC } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhone, FiMail } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";

interface FormProps {}

const Form: FC<FormProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <form action="#" method="POST" className="mx-auto w-full orange-bg">
      <div className="w-full grid grid-cols-2 gap-x-8 gap-y-6">
        <div>
          <label
            htmlFor="name"
            className="block text-xs font-poppins text-white"
          >
            {t('contact.form.name')}
          </label>
          <div className="mt-2.5">
            <input
              required
              type="text"
              name="name"
              id="name"
              autoComplete="given-name"
              className="block w-full text-white bg-[#ffffff09] rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-[#D2D5DA] placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-moss-green marker:border-0"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="company"
            className="block text-xs font-poppins text-white"
          >
            {t('contact.form.company')}
          </label>
          <div className="mt-2.5">
            <input
              required
              type="text"
              name="company"
              id="company"
              autoComplete="given-company"
              className="block w-full text-white bg-[#ffffff09] rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-[#D2D5DA] placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-moss-green marker:border-0"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-xs font-poppins text-white"
          >
            {t('contact.form.email')}
          </label>
          <div className="mt-2.5">
            <input
              required
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              className="block w-full text-white bg-[#ffffff09] rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-[#D2D5DA] placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-moss-green marker:border-0"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="phone"
            className="block text-xs font-poppins text-white"
          >
            {t('contact.form.phone')}
          </label>
          <div className="mt-2.5">
            <input
              required
              type="text"
              name="phone"
              id="phone"
              autoComplete="tel"
              className="block w-full text-white bg-[#ffffff09] rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-[#D2D5DA] placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-moss-green marker:border-0"
            />
          </div>
        </div>

        <div className="col-span-2">
          <label
            htmlFor="message"
            className="block text-xs font-poppins text-white"
          >
            {t('contact.form.message')}
          </label>
          <div className="mt-2.5">
            <textarea
              required
              name="message"
              id="message"
              rows={4}
              className="block w-full text-white bg-[#ffffff09] rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-[#D2D5DA] placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-moss-green marker:border-0"
            />
          </div>
        </div>
      </div>
      <div className="flex mt-6">
        <button
          type="submit"
          className="text-white w-full rounded-md border bg-[#f18b1f] border-[#f18b1f] hover:bg-[#f18b1fc6] hover:text-white flex justify-center py-3 tracking-wider transition-all duration-300 ease-in-out"
        >
          {t('contact.form.send')}
        </button>
      </div>
    </form>
  );
};

function FormContainer() {
  const { t } = useTranslation();
  return (
    <Section position="top">
      <div className="flex w-full justify-between max-md:flex-col max-md:gap-16">
        <div className="flex flex-col max-md:gap-0 gap-16 max-md:w-full w-1/2">
          <h1 className="default-title font-semibold text-white max-md:text-center tracking-wide font-syne max-md:pb-4 max-md:w-full">
            {t('contact.text.title')}
          </h1>

          <div className="max-md:pb-16">
            <p className="white-paragraph font-poppins text-white pr-[20%] max-md:pr-0 max-md:text-center">
              {t('contact.text.paragraph')}
            </p>
          </div>

          <div className="flex flex-col gap-5 max-md:w-full max-md:items-center">
            <a href="">
              <div className="text-moss-green flex items-center gap-2">
                <HiOutlineLocationMarker size={16} className="md:hidden" />
                <HiOutlineLocationMarker size={22} className="max-md:hidden" />
                <span className="text-white max-md:text-xs text-sm font-poppins">
                  {t('contact.text.info.location')}
                </span>
              </div>
            </a>

            <a href={`mailto:${t('contact.text.info.email')}`}>
              <div className="text-moss-green flex items-center gap-2">
                <FiMail size={16} className="md:hidden" />
                <FiMail size={22} className="max-md:hidden" />
                <span className="text-white max-md:text-xs text-sm font-poppins">
                  {t('contact.text.info.email')}
                </span>
              </div>
            </a>

            <a href="">
              <div className="text-moss-green flex items-center gap-2">
                <FiPhone size={16} className="md:hidden" />
                <FiPhone size={22} className="max-md:hidden" />
                <span className="text-white max-md:text-xs text-sm font-poppins">
                  {t('contact.text.info.phone')}
                </span>
              </div>
            </a>
          </div>
        </div>

        <div className="w-1/2 flex justify-end max-md:w-full">
          <Form />
        </div>
      </div>
    </Section>
  );
}

export default FormContainer;
