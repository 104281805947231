import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components";
import "../../../styles/global.css";

interface Step {
  number: string;
  title: string;
  dotOneTitle: string;
  dotOneText: string;
  dotTwoTitle: string;
  dotTwoText: string;
  dotThreeTitle: string;
  dotThreeText: string;
}

function Process() {
  const { i18n, t } = useTranslation();
  const [steps, setSteps] = useState<Step[]>([]);

  useEffect(() => {
    const loadSteps = async () => {
      const language = i18n.language === "sv" ? "sv" : "en";
      const stepsData = await import(`../../../data/process_${language}.json`);
      setSteps(stepsData.default);
    };

    loadSteps();
  }, [i18n.language]);

  return (
    <Section position="default">
      <div className="w-full flex justify-center items-center mb-20">
        <div className="flex flex-col gap-5">
          <div className="flex justify-center">
            <span className="marked-title">{t("price.process.markedTitle")}</span>
          </div>
          <h1 className="default-title text-white">{t("price.process.title")}</h1>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-5">
        {steps.map((step, index) => (
          <div key={index} className="col-span-1 p-2.5 flex flex-col gap-2.5">
            <div className="flex gap-1 justify-start items-center">
              <p className="text-sm font-syne text-moss-green leading-none">{step.number}</p>
              <h1 className="text-white font-syne text-2xl leading-none">
                {step.title}
              </h1>
            </div>
            <ul className="flex flex-col gap-2.5 text-sm default-paragraph text-white/85">
              <li><span className="font-semibold text-moss-green">{step.dotOneTitle}</span>{step.dotOneText}</li>
              <li><span className="font-semibold text-moss-green">{step.dotTwoTitle}</span>{step.dotTwoText}</li>
              <li><span className="font-semibold text-moss-green">{step.dotThreeTitle}</span>{step.dotThreeText}</li>
            </ul>
          </div>
        ))}
      </div>
    </Section>
  );
}

export default Process;
