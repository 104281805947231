import React from "react";
import { Section } from "../../../components";
import { MdArrowOutward } from "react-icons/md";

interface AboutProps {
    image?: string;
    company?: string;
    title?: string;
    paragraphOne?: string;
    paragraphTwo?: string;
    website?: string;
}

function About( props: AboutProps ) {
    return(
        <>
            <Section position="top">
                <div className="w-full flex max-md:gap-0 gap-20">
                    <div className="w-1/2 flex items-center max-md:hidden">
                        <img className="h-[30rem] w-full rounded-[20px] object-cover" src={props.image} loading="lazy" alt="" />
                    </div>

                    <div className="max-md:w-full w-1/2 flex flex-col justify-center gap-10">
                        <div className="flex flex-col gap-1">
                            <h2 className="font-syne text-lg text-white">{props.company}</h2>
                            <h1 className="default-title text-white">{props.title}</h1>
                        </div>

                        <div className="flex flex-col gap-5">
                            <p className="white-paragraph">
                                {props.paragraphOne}
                            </p>

                            <p className="white-paragraph">
                                {props.paragraphTwo}
                            </p>
                        </div>

                        <div className="flex justify-start items-center">
                            <a href={`https://${props.website}`} target="_blank" rel="noreferrer" className="group flex items-center text-white hover:underline hover:underline-offset-3 gap-2">
                                {props.website}
                                    <div className="relative overflow-hidden flex justify-center items-center w-2.5 h-2.5 mt-[2px] font-syne">
                                        <MdArrowOutward
                                            className="absolute group-hover:translate-x-8 group-hover:-translate-y-8 transition-all ease-in-out duration-500"
                                            color="#fff"
                                            size={20}
                                        />
                                        <MdArrowOutward
                                            className="absolute -translate-x-8 translate-y-8 transition-all ease-in-out duration-500 group-hover:translate-x-0 group-hover:translate-y-0"
                                            color="#fff"
                                            size={20}
                                        />
                                    </div>
                            </a>
                        </div>
                    </div>

                    <div className=""></div>
                </div>
            </Section>
        </>
    );
}

export default About;